<template lang="es-ES">
<div>
    <!-- <html>
    <body> -->

    <header class="">
        <div class="imp__header_menu_movil">
            <ul class="af__header_menu_movil-conteiner">
                <div>
                    <li>
                        <a class="pad" href="https://imperu.com.pe/"><img class="logo_prin" src="https://imperu.com.pe/wp-content/uploads/2022/04/Group-2664.svg"></a>
                    </li>
                </div>
                <i class="icon_menu fas fa-bars"></i>
            </ul>
        </div>

        <nav>
            <ul class="imp__logo_pc">
                <li>
                    <a href="https://imperu.com.pe/"><img class="logo_prin" src="https://imperu.com.pe/wp-content/uploads/2022/04/Group-2664.svg"></a>
                </li>
            </ul>
            <ul id="menu-menu-principal" class="menu">
                <li style="color:black" id="menu-item-268" class="fas fa-angle-right menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-268"><a style="color:black" href="https://imperu.com.pe/proyectos/">Proyectos</a>
                    <ul class="sub-menu">
                        <li id="menu-item-713" class="fas fa-angle-right menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-713"><a href="#" style="color:black">Disponibles</a>
                            <ul class="sub-menu">
                                <li id="menu-item-715" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-715"><a href="https://imperu.com.pe/project/la-alameda-de-miraflores-country/" style="color:black">La Alameda de Miraflores Country</a></li>
                            </ul>
                        </li>
                        <li id="menu-item-714" class="fas fa-angle-right menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-714"><a href="#" style="color:black">Culminados</a>
                            <ul class="sub-menu">
                                <li id="menu-item-716" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-716"><a href="https://imperu.com.pe/project/miraflores-country-club-2/" style="color:black">Miraflores Country Club 2</a></li>
                                <li id="menu-item-717" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-717"><a href="https://imperu.com.pe/project/boulevard-park-plaza-ii/" style="color:black">Boulevard Park Plaza II</a></li>
                                <li id="menu-item-718" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-718"><a href="https://imperu.com.pe/project/alameda-country-club/" style="color:black">Alameda Country Club</a></li>
                                <li id="menu-item-719" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-719"><a href="https://imperu.com.pe/project/residencial-acacias/" style="color:black">Residencial Acacias</a></li>
                                <li id="menu-item-720" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-720"><a href="https://imperu.com.pe/project/boulevard-park-plaza-i/" style="color:black">Boulevard Park Plaza I</a></li>
                                <li id="menu-item-721" class="menu-item menu-item-type-post_type menu-item-object-project menu-item-721"><a href="https://imperu.com.pe/project/miraflores-country-club-1/" style="color:black">Miraflores Country Club 1</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li id="menu-item-1270" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-926 current_page_item menu-item-1270"><a style="color:black" href="https://app.imperu.com.pe/referidos/" aria-current="page">Referidos</a></li>
                <li id="menu-item-25" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25"><a href="https://imperu.com.pe/posventa/" style="color:black">Posventa</a></li>
                <li id="menu-item-24" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24"><a href="https://imperu.com.pe/nosotros/" style="color:black">Nosotros</a></li>
                <li id="menu-item-23" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-23"><a href="https://imperu.com.pe/blog/" style="color:black">Blog</a></li>
                <li id="menu-item-22" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-22"><a href="https://imperu.com.pe/contacto/" style="color:black">Contacto</a></li>
            </ul>
        </nav>

    </header>

    <div class="imp__content-page">
        <!-- <div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid imp__banner vc_custom_1657142343083 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex" >
            <div class="wpb_column vc_column_container vc_col-sm-12" style="margin-top: -65px">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element  refiere__imp__banner">
                            <div class="wpb_wrapper">
                                <h2>Refiere y gana</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid imp__banner vc_custom_1657142343083 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex" style="position: relative; left: -311.5px; box-sizing: border-box; width: 1903px; padding-left: 311.5px; padding-right: 311.5px;">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element  refiere__imp__banner">
                            <div class="wpb_wrapper">
                                <h2>Refiere y gana</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="vc_row-full-width vc_clearfix"></div>
        <div id="banner__imp_pdd" data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="wpb_single_image wpb_content_element vc_align_center">

                            <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper vc_box_border_grey"><img width="2220" height="968" src="https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1.jpg" class="vc_single_image-img attachment-full" alt="" loading="lazy" srcset="https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1.jpg 2220w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-300x131.jpg 300w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-1024x447.jpg 1024w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-768x335.jpg 768w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-1536x670.jpg 1536w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-2048x893.jpg 2048w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-380x166.jpg 380w, https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1-480x209.jpg 480w" sizes="(max-width: 2220px) 100vw, 2220px"></div>
                                <!-- <v-img src="https://imperu.com.pe/wp-content/uploads/2022/07/banner-referidos-1.jpg"></v-img> -->
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row-full-width vc_clearfix"></div>
        <div class="vc_row wpb_row vc_row-fluid refer__txt__frds">
            <div class="refe__margin wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <h2>¿Conoces algún interesado<br> en comprar un lote?</h2>
                                <p>Amigos, familiares, colegas, etc.</p>

                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_inner vc_row-fluid refe__grenn">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <p>¡Sigue los siguientes<br> pasos y gana S/ 500!</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid info_referido vc_row-o-content-middle vc_row-flex">
            <div class="wpb_column vc_column_container vc_col-sm-6">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="vc_row wpb_row vc_inner vc_row-fluid imp__sec2 vc_row-o-content-middle vc_row-flex">
                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">

                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="115" height="229" src="https://imperu.com.pe/wp-content/uploads/2022/07/shutterstock_31295920-1-1.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left   imp__img_sec2">

                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="52" height="52" src="https://imperu.com.pe/wp-content/uploads/2022/07/Group-2965.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></div>
                                            </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element  refe__pic">
                                            <div class="wpb_wrapper">
                                                <p>Ingresa en nuestro formulario tus datos y los de tu referido.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-6">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="vc_row wpb_row vc_inner vc_row-fluid imp__sec2 vc_row-o-content-middle vc_row-flex">
                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">

                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="142" height="114" src="https://imperu.com.pe/wp-content/uploads/2022/07/Group-2972.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left   imp__img_sec2">

                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="52" height="52" src="https://imperu.com.pe/wp-content/uploads/2022/07/Group-2966.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></div>
                                            </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element  refe__pic">
                                            <div class="wpb_wrapper">
                                                <p>Verifica que los datos consignados sean los correctos y presiona el botón “Enviar”.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid info_referido info_referido_last vc_row-o-content-middle vc_row-flex">
            <div class="wpb_column vc_column_container vc_col-sm-3">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"></div>
                </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-6">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="vc_row wpb_row vc_inner vc_row-fluid imp__sec2 vc_row-o-content-middle vc_row-flex">
                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">

                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="144" height="220" src="https://imperu.com.pe/wp-content/uploads/2022/07/shutterstock_129043760-1.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-6">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_left   imp__img_sec2">

                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="vc_single_image-wrapper   vc_box_border_grey"><img width="52" height="52" src="https://imperu.com.pe/wp-content/uploads/2022/07/Group-2973.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></div>
                                            </figure>
                                        </div>

                                        <div class="wpb_text_column wpb_content_element  refe__pic">
                                            <div class="wpb_wrapper">
                                                <p>Si te enteraste de esta campaña por un asesor de IMP, señala su nombre en la lista desplegable del formulario web.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-3">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"></div>
                </div>
            </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid vc_custom_1657263722368 vc_row-o-content-middle vc_row-flex">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <p style="text-align: center;">(*)No olvides leer los:</p>

                            </div>
                        </div>
                        <div class="vc_btn3-container  btn_terminos__refe vc_btn3-center" id="btn_terminos__refe"><a class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey" href="https://imperu.com.pe/referidos/terminos-y-condiciones/" title="Refiere y gana">TÉRMINOS Y CONDICIONES DE CAMPAÑA</a></div>
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <p style="text-align: center;">antes de enviar tus datos</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row wpb_row vc_row-fluid imp__refereidos_1 vc_custom_1657309804556">
            <div class="wpb_column vc_column_container vc_col-sm-2">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"></div>
                </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-8">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper" style="background:;">
                        <div role="form" class="wpcf7" id="wpcf7-f928-p926-o1" lang="es-ES" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form action="/referidos/#wpcf7-f928-p926-o1" method="post" class="wpcf7-form init" novalidate="novalidate" data-status="init">
                               <!--  <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="928">
                                    <input type="hidden" name="_wpcf7_version" value="5.5.6">
                                    <input type="hidden" name="_wpcf7_locale" value="es_ES">
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f928-p926-o1">
                                    <input type="hidden" name="_wpcf7_container_post" value="926">
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="">
                                    <input type="hidden" name="_wpcf7_recaptcha_response" value="03AD1IbLBVZoKkv9Xw5pk_ebunG-mNIVYoibE4gq0oTHtGdTSaxMe-Bc-h7N-1jxBzWLgilFgM1dAV8AbPcQXbvKRThOKPlC-wi8Qxgj3x11HW5sBJ4I1TTz_57NyLAudAvdGhiuM9qDiySyNLbFmdq0QaLiQbzF6pMEV9ne9yBsdkYV7CprDba5SMtwoOrw5tDw4c9vgUF0UZi3AFcNPNpT_tnRnA0-Eh3Rsfbl5O4vcDVbbFM7tCGTpjFp756hya73Lu6HxlpkEciy9TxqCjerWxwrJjJ077oPSvQngvE074kv-dXCuLtd3sOP8Wbtkbd4nOkTOkPt2c9I8w6OFWDgodUAwHJwIxD5FvrIlvqAiv6y8tSPoAe-I2RpBWR_ubMqVBTh3RwdDewKr1TzZN3VMiTwwdf_3IO8KC9grOHJhV18o8RfYIC0_q2RceIqi0Z8spj9cBe89qsx6WhyHfj0xgrLZexitIDZGFAoF-_VB8Ab3j2sWm1bzSl9iK5nIvKpqh2RXVrZ-K2B0QrGIbiB8J3hNpirIZWw">
                                </div> -->
                                <div class="imp__form">
                                    <h2 style="margin-top:-10px">Registra tus datos</h2>
                                    <p><span class="wpcf7-form-control-wrap your-name">
                                            <!-- <input type="text" name="your-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" aria-required="true" aria-invalid="false" placeholder="Nombres"> -->
                                            <s-text style="background: white;border-radius: 700px;" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" placeholder="Nombres" v-model="item.Nombres_referido" ref="Nombres_referido"></s-text>
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap your-surname">
                                            <!-- <input type="text" name="your-surname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" aria-required="true" aria-invalid="false" placeholder="Apellidos"> -->
                                            <s-text style="background: white;border-radius: 700px;" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" placeholder="Apellidos" v-model="item.Apellidos_referido" ref="Apellidos_referido"></s-text>
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap your-dni">
                                            <s-text style="background: white;border-radius: 700px;" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" placeholder="DNI" number v-model="item.Dni_referido" ref="Dni_referido"></s-text>
                                            <!-- <input type="number" name="your-dni" value="" class="wpcf7-form-control wpcf7-number wpcf7-validates-as-required wpcf7-validates-as-number cnumber" aria-required="true" aria-invalid="false" placeholder="DNI"> -->
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap your-phone">
                                            <!-- <input type="tel" name="your-phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cnumber" aria-required="true" aria-invalid="false" placeholder="Teléfono celular"> -->
                                            <s-text style="background: white;border-radius: 700px;" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" number placeholder="Teléfono celular" v-model="item.Celular_referido" ref="Celular_referido"></s-text>
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap your-phone">
                                            <!-- <input type="tel" name="your-phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cnumber" aria-required="true" aria-invalid="false" placeholder="Teléfono celular"> -->
                                            <s-text style="background: white;border-radius: 700px;" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" placeholder="Email" v-model="item.Correo_referido" ref="Correo_referido"></s-text>
                                        </span><br>
                                        <!--  <span class="wpcf7-form-control-wrap your-email">
                                            <input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Email">
                                            <s-text style="background: white;border-radius: 700px;"  size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" placeholder="Email"></s-text>
                                        </span> -->
                                    </p>
                                </div>
                               <div class="select">
                                    <span class="wpcf7-form-control-wrap proyectos">
                                        <h4 style="margin-top:0px;margin-bottom:-10px">Si te enteraste de la campaña por un asesor de IMP selecciónalo aquí</h4>
                                        <v-select
                                            :items="itemsAsesor"
                                            label="Seleccione..."
                                            hide-details 
                                            item-value="DedValue"
                                            item-text="DedDescription"
                                            v-model="objAsesor"
                                            return-object
                                            style="color:red"
                                            
                                        ></v-select>
                                        <!-- <s-select-definition-extern :def="1184" v-model="objAsesor" label="Si te enteraste de la campaña por un asesor de IMP selecciónalo aquí"></s-select-definition-extern> -->
                                       <!--  <select name="proyectos" class="wpcf7-form-control wpcf7-select" aria-invalid="false">
                                            <option value="">Si te enteraste de la campaña por un asesor de IMP selecciónalo aquí</option>
                                            <option value="Gino Saenz Carrion">Gino Saenz Carrion</option>
                                            <option value="Alberto Mejía Núñez">Alberto Mejía Núñez</option>
                                            <option value="Willy Lecussan Casanova">Willy Lecussan Casanova</option>
                                            <option value="Mariano R. Calero Romero">Mariano R. Calero Romero</option>
                                            <option value="Luis F. Wong Arechaga">Luis F. Wong Arechaga</option>
                                            <option value="Vanessa M. Bruno Tello">Vanessa M. Bruno Tello</option>
                                            <option value="Eduardo S. Alburqueque López">Eduardo S. Alburqueque López</option>
                                            <option value="Mayra Juliana Mauriola">Mayra Juliana Mauriola</option>
                                            <option value="Antonio Delgado Wong">Antonio Delgado Wong</option>
                                            <option value="No cuento con ningún asesor">No cuento con ningún asesor</option>
                                        </select> -->
                                    </span>
                                </div> 
                                <div class="imp__form_dto">
                                    <h2>Registra a tu referido</h2>
                                    <p>
                                        <span class="wpcf7-form-control-wrap syour-name">
                                            <!-- <input type="text" name="syour-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" aria-required="true" aria-invalid="false" placeholder="Nombres"> -->
                                            <s-text style="background: white;border-radius: 700px;" autofocus placeholder="Nombres" v-model="item.Nombres" ref="Nombres"></s-text>
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap syour-surname">
                                            <!-- <input type="text" name="syour-surname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required ctext" aria-required="true" aria-invalid="false" placeholder="Apellidos"> -->
                                            <s-text style="background: white;border-radius: 700px;" placeholder="Apellidos" v-model="item.Apellidos" ref="Apellidos"></s-text>
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap syour-email">
                                            <!-- <input type="email" name="syour-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Email"> -->
                                            <s-text style="background: white;border-radius: 700px;" placeholder="Email" v-model="item.Correo" ref="Correo"></s-text>
                                        </span><br>
                                        <span class="wpcf7-form-control-wrap syour-phone">
                                            <!-- <input type="tel" name="syour-phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel cnumber" aria-required="true" aria-invalid="false" placeholder="Teléfono celular"> -->
                                            <s-text style="background: white;border-radius: 700px;" placeholder="Teléfono celular" v-model="item.Celular" number ref="Celular"></s-text>
                                        </span>
                                    </p>
                                </div>
                                <li class="imp_contact_verif">
                                    <span class="wpcf7-form-control-wrap politica-privacidad">
                                        <span class="wpcf7-form-control wpcf7-acceptance">
                                            <span class="wpcf7-list-item" style="display: flex;">
                                                <v-checkbox  style="margin-top: -3px;margin-left:20px" v-model="checkbox1" label="" color="red" hide-details></v-checkbox>
                                                <span class="ml-1 mt-1">Acepto la <a target="_blank" rel="nofollow" href="https://imperu.com.pe/politicas-de-privacidad/"> Política de Privacidad.</a></span>
                                            </span>
                                        </span>
                                    </span>
                                </li>

                                <li class="imp_contact_verif">
                                   <span class="wpcf7-form-control-wrap politica-privacidad">
                                        <span class="wpcf7-form-control wpcf7-acceptance">
                                           <span class="wpcf7-list-item" style="display: flex;">
                                                <v-checkbox  style="margin-top: -3px;margin-left:20px" v-model="checkbox2" label="" color="red" hide-details></v-checkbox>
                                                <span class="ml-1 mt-1">Acepto los <a target="_blank" rel="nofollow" href="https://imperu.com.pe/wp-content/uploads/2020/06/T%C3%A9rminos-y-Condiciones-P%C3%A1gina-Web-IMP-1.pdf">Términos y Condiciones.</a></span>
                                            </span>
                                        </span>
                                    </span> 
                                </li>
                              
                                <li class="imp__send">
                                    <!-- <input @click="save()" type="submit" value="ENVIAR" class="wpcf7-form-control has-spinner wpcf7-submit" disabled=""> -->
                                    <!-- <span class="wpcf7-spinner"></span> -->
                                    <v-btn :disabled="disabledBtn" @click="save()" width="400px" elevation="0" color="success" small class="wpcf7-form-control has-spinner wpcf7-submit">Enviar</v-btn>
                                </li>
                                <!-- <input type="hidden" name="utm_source" value="" class="wpcf7-form-control wpcf7-hidden" ref="utm_source" id="utm_source">
                                <input type="hidden" name="utm_medium" value="" class="wpcf7-form-control wpcf7-hidden" id="utm_medium">
                                <input type="hidden" name="utm_campaign" value="" class="wpcf7-form-control wpcf7-hidden" ref="utm_campaign" id="utm_campaign">
                                <input type="hidden" name="utm_content" value="" class="wpcf7-form-control wpcf7-hidden" id="utm_content">
                                <input type="hidden" name="utm_term" value="" class="wpcf7-form-control wpcf7-hidden" id="utm_term">
                                <div class="wpcf7-response-output" aria-hidden="true"></div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wpb_column vc_column_container vc_col-sm-2">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"></div>
                </div>
            </div>
        </div>
        <div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid vc_custom_1652907201023 vc_row-has-fill">
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element  imp__asociados">
                            <div class="wpb_wrapper">
                                <p>Asociados a:</p>

                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_inner vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-2">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper"></div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-4">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">

                                            <figure class="wpb_wrapper vc_figure">
                                                <a href="https://dci.pe/" target="_blank" class="vc_single_image-wrapper   vc_box_border_grey"><img width="281" height="130" src="https://imperu.com.pe/wp-content/uploads/2022/05/1.png" class="vc_single_image-img attachment-full" alt="" loading="lazy"></a>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-4">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_single_image wpb_content_element vc_align_center">

                                            <figure class="wpb_wrapper vc_figure">
                                                <a href="https://adiperu.pe/" target="_blank" class="vc_single_image-wrapper   vc_box_border_grey"><img width="379" height="130" src="https://imperu.com.pe/wp-content/uploads/2022/05/2.png" class="vc_single_image-img attachment-full" alt="" loading="lazy" srcset="https://imperu.com.pe/wp-content/uploads/2022/05/2.png 379w, https://imperu.com.pe/wp-content/uploads/2022/05/2-300x103.png 300w" sizes="(max-width: 379px) 100vw, 379px"></a>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-2">
                                <div class="vc_column-inner">
                                    <div class="wpb_wrapper"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row-full-width vc_clearfix"></div>
    </div>
    <div class="imp__btn-wsp">
        <li>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=51985184949&amp;text=Vi%20esto%20en%20su%20web"><img src="https://imperu.com.pe/wp-content/uploads/2021/10/WHATSAPP.svg" alt=""></a>
        </li>
    </div>

    <footer>

        <div class="footer_container">
            <div>
                <li><a href="https://imperu.com.pe/posventa/">Posventa</a></li>

                <li><a href="https://imperu.com.pe/nosotros/">Nosotros</a></li>

                <li><a href="https://imperu.com.pe/blog/">Blog</a></li>

                <li><a href="mailto:contacto@imperu.com.pe">Trabaja con nosotros</a></li>

            </div>
            <div>
                <li><a href="https://imperu.com.pe/libro-de-reclamaciones/">Libro de reclamaciones virtual</a></li>

                <li><a href="https://imperu.com.pe/politicas-de-privacidad/">Políticas de privacidad </a></li>

                <li><a href="https://imperu.com.pe/terminos-y-condiciones/">Términos y condiciones</a></li>

                <li><a href="https://imperu.sharepoint.com/sites/DirectorioIMP">Intranet</a></li>

            </div>
            <div>
                <span>Comunícate con nosotros:</span>
                <p>Central telefónica: 073 - 626400</p>

                <p>Servicio al cliente: Anexo 5000</p>

                <p>Ventas: +51985184949</p>

                <p>contacto@imperu.com.pe</p>

            </div>
            <div>
                <div>
                    <img src="https://imperu.com.pe/wp-content/uploads/2021/10/Group-2760.svg" alt=" ">
                    <span>Oficinas y sala de ventas:</span>
                </div>
                <p>Urb. Miraflores Country Club Mz. CA lotes 35, 36 y 37, Castilla – Piura</p>
            </div>
            <div>
                <p>Síguenos en</p>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/IMPeru/" target="_blank">
                            <img src="https://imperu.com.pe/wp-content/uploads/2021/10/Vector-1.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/inmobiliaria.imp/" target="_blank">
                            <img src="https://imperu.com.pe/wp-content/uploads/2021/10/Vector-2.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://pe.linkedin.com/company/imperu" target="_blank">
                            <img src="https://imperu.com.pe/wp-content/uploads/2021/10/Group-2758.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCajpPeUei-8XqSR_H4LpESA" target="_blank">
                            <img src="https://imperu.com.pe/wp-content/uploads/2021/10/Group-2762.svg" alt="">
                        </a>
                    </li>
                </ul>
                <ul>
                </ul>
            </div>
        </div>

        <div class="footer__copy">
            <p>Inmobiliaria Miraflores Peru S.A.C. ® Todos los derechos reservados.</p>
        </div>

    </footer>
    <!-- </body>
    </html> -->
</div>
</template>

<script>
import _sReferrals from '@/services/WebSiteIMP/WebReferralsService.js'
export default {
    data: () => ({

        html: '',
        item: {
            IdTipoPortal: 12,
            IdProyecto: 1587,
            IdTipoDocumento: 1,
            NroDocumento: "",
            Nombres: "",
            Apellidos: "",
            Correo: "",
            Celular: "",
            Comentario: "",
            IncluyeUtm: true,
            utm_campaign: "referidos",
            utm_content: "referidos",
            utm_medium: "referidos",
            utm_source: "referidos",
            utm_term: "referidos",
            Lead_Referido: true,
            Nombres_referido: "",
            Apellidos_referido: "",
            Dni_referido: "",
            Celular_referido: "",
        },
        chk: {
            
        },
        checkbox1: false,
            checkbox2: false,
        
        objAsesor: null,
        itemsAsesor: [],
        disabledBtn: true

    }),

    watch:{
        checkbox1(){
            if(this.checkbox1 == true && this.checkbox2 == true){
                this.disabledBtn = false
            }else{
                this.disabledBtn = true
            }
        },
        checkbox2(){
            if(this.checkbox1 == true && this.checkbox2 == true){
                this.disabledBtn = false
            }else{
                this.disabledBtn = true
            }
        }
    },

    mounted() {
        _sReferrals.definitiongroupURL(1184, 0, 42).then(resp => {
            this.itemsAsesor = resp.data
        })
    },

    methods: {

        /* getParameterByName(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        }, */

        save() {

            //DATOS REFERIDO
            if (this.item.Nombres == "" || this.item.Nombres.length == 0) {
                this.$refs.Nombres.error("Ingrese Nombres de referido")
                return
            }

            if (this.item.Apellidos == "" || this.item.Apellidos.length == 0) {
                this.$refs.Apellidos.error("Ingrese Apellidos de referido")
                return
            }

            if (this.item.Correo == "" || this.item.Correo.length == 0) {
                this.$refs.Correo.error("Ingrese Correo de referido")
                return
            }

            if (!this.$fun.isValidEmail(this.item.Correo)) {
                this.$refs.Correo.focus();
                this.$refs.Correo.error("Correo invalido");
                /* this.$fun.alert("Correo invalido", "warning"); */
                return;
            }

            if (this.item.Celular == "" || this.item.Celular.length == 0) {
                this.$refs.Celular.error("Ingrese Celular de referido")
                return
            }


            //DATOS QUIEN REFIERE

            if (this.item.Nombres_referido == "" || this.item.Nombres_referido.length == 0) {
                this.$refs.Nombres_referido.error("Ingrese Nombres")
                return
            }

            if (this.item.Apellidos_referido == "" || this.item.Apellidos_referido.length == 0) {
                this.$refs.Apellidos_referido.error("Ingrese Apellidos")
                return
            }

            if (this.item.Dni_referido == "" || this.item.Dni_referido.length == 0) {
                this.$refs.Dni_referido.error("Ingrese DNI")
                return
            }


            if (this.item.Celular_referido == "" || this.item.Celular_referido.length == 0) {
                this.$refs.Celular_referido.error("Ingrese Celular")
                return
            }

            if (this.item.Correo_referido == "" || this.item.Correo_referido.length == 0) {
                this.$refs.Correo_referido.error("Ingrese Email")
                return
            }

            if (!this.$fun.isValidEmail(this.item.Correo_referido)) {
                this.$refs.Correo_referido.focus();
                this.$refs.Correo_referido.error("Correo invalido");
                /* this.$fun.alert("Correo invalido", "warning"); */
                return;
            }

            

            if(this.objAsesor == null){
                this.$fun.alert("Seleccione asesor, caso contrario seleccione no cuento con ningun asesor", "warning")
                return
            }


            if(!this.checkbox1 && !this.checkbox1){
                this.$fun.alert("Click en Acepto la Política de Privacidad y Acepto los Términos y Condiciones.", "warning")
                return
            }

            this.item.codVendedor = this.objAsesor.DedHelper

            this.item.utm_campaign = this.$fun.getParameterByName('utm_campaign') == "" ? "referidos" : this.$fun.getParameterByName('utm_campaign')
            this.item.utm_content = this.$fun.getParameterByName('utm_content') == "" ? "referidos" : this.$fun.getParameterByName('utm_content')
            this.item.utm_medium = this.$fun.getParameterByName('utm_medium') == "" ? "referidos" : this.$fun.getParameterByName('utm_medium')
            this.item.utm_source = this.$fun.getParameterByName('utm_source') == "" ? "referidos" : this.$fun.getParameterByName('utm_source')
            this.item.utm_term = this.$fun.getParameterByName('utm_term') == "" ? "referidos" : this.$fun.getParameterByName('utm_term')


            console.log("save", this.item)

            this.$fun.alert("Seguro de registrar información ?", "question", false).then(val => {
                if (val.value) {
                    _sReferrals.save(this.item, 42).then(resp => {
                        if (resp.status == 200) {
                            if(resp.data == "000"){
                                this.$fun.alert("Registrado correctamente", "success", false)
                                this.$router.go(0);
                            }else{
                                this.$fun.alert("[IMP]. Error al registrar datos, vuelva a intentarlo. Si el problema persiste favor acercarse a oficina.", "error", false)
                            }
                            
                        }
                    })
                }
            })

        }
    },
};
</script>

<style scoped>

@import "../../styles/extern/css/js_composer.min.css";
@import "../../styles/extern/imp-theme/styleFooter.css";
</style>

<style scoped>
         li.imp_contact_verif {
            list-style: none !important;
        }
        
        .input.wpcf7-form-control.has-spinner.wpcf7-submit {
            background: #25D366 !important;
            border-radius: 75px !important;
            border-color: #25D366 !important;
        }
        
        form.wpcf7-form.init {
            border: 1px solid #FFFFFF;
            border-radius: 8px;
        }
        
        .imp__form {
            padding: 0px 15px;
        }
        
        div#banner__imp_pdd .vc_column-inner {
            padding: 0 !important;
        }
        
        .imp__form_dto {
            padding: 0 15px;
        }
        
        @media(max-width:768px) {
            .vc_row.wpb_row.vc_inner.vc_row-fluid.imp__sec2 {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .imp__sec2 .wpb_column.vc_column_container.vc_col-sm-3 {
                width: 50%;
            }
            .imp__img_sec2 {
                text-align: center !important;
            }
            div#banner__imp_pdd {
                padding: 0 !important;
            }
        }
        
        .imp__asociados p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 800;
            font-size: 23px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #0500FF;
            padding-bottom: 40px;
        }

        
        li.imp__send {
            justify-content: center !important;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        
       
        
        .imp__refereidos_1 .vc_column-inner form input {
            background: #FFFFFF !important;
            border: 1px solid #FFFFFF !important;
            border-radius: 8px !important;
            padding: 4px 12px !important;
        }
        
        li.imp_contact_verif input {
            margin-right: 4px !important;
        }
        
        label {
            display: flex;
            align-items: center;
        }
        
        li.imp_contact_verif span {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 24px;
            letter-spacing: 0.16px;
            text-decoration-line: underline;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #1f1d1d;
        }
        
        li.imp_contact_verif a {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 24px;
            letter-spacing: 0.16px;
            text-decoration-line: underline;
            font-feature-settings: 'pnum' on, 'lnum' on;
            
        }

        .imp__form_dto h2,
        .imp__form h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            text-transform: uppercase;
            
 
        }
        
        .refiere__imp__banner {
            margin: 0 !important;
        }
        
        .refiere__imp__banner h2 {
            font-family: 'Lato';
            font-weight: 800;
            font-size: 24px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            mix-blend-mode: normal;
        }
        
        .refer__txt__frds h2 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 800;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: #0500FF;
            mix-blend-mode: normal;
        }
        
        .refer__txt__frds p {
            font-family: 'Lato';
            font-style: italic;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            mix-blend-mode: normal;
            margin: 0 !important;
            padding: 15px;
        }
        
        .refe__margin {
            margin: 0 !important;
        }
        
        .refer__txt__frds {
            margin: 0 !important;
        }
        
        .refer__txt__frds .vc_column-inner {
            padding: 0 !important;
        }
        
        .wpb_column.vc_column_container.vc_col-sm-12 {
            padding: 0 !important;
        }
        
        .refe__grenn {
            margin: 0 !important;
        }
        
        .refe__grenn p {
            font-family: 'Lato';
            font-style: italic;
            font-weight: 800;
            font-size: 24.87px;
            line-height: 25px;
            text-align: center;
            color: #25D366;
            padding: 10px !important;
            mix-blend-mode: normal;
            border: 0.748387px solid #25D366;
            border-radius: 18.7097px;
            width: 296px;
            max-width: 100%;
            margin: auto !important;
        }
        
        .refe__pic {
            margin: 0 !important;
        }
        
        .refe__pic p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #444444;
            mix-blend-mode: normal;
            margin: 0 !important;
        }
        
        #btn_terminos__refe {
            padding-top: 0px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
            border-radius: 8px;
        }
        
        #btn_terminos__refe button.vc_general.vc_btn3.vc_btn3-size-md.vc_btn3-shape-rounded.vc_btn3-style-modern.vc_btn3-color-grey {
            background: #0500FF;
            border-radius: 38.7546px;
        }
        
        #btn_terminos__refe .vc_btn3.vc_btn3-color-grey.vc_btn3-style-modern {
            text-align: center;
            color: #FFFFFF;
            background: #0500FF;
            border-radius: 75px;
            border-color: #0500FF;
        }
        
        #btn__footer button.vc_general.vc_btn3.vc_btn3-size-md.vc_btn3-shape-rounded.vc_btn3-style-modern.vc_btn3-color-grey {
            background: #0500FF;
            border-radius: 75px;
        }
        
        #btn__footer .vc_btn3.vc_btn3-color-grey.vc_btn3-style-modern {
            text-align: center;
            color: #FFFFFF;
        }
        
        .imp__text__rf2 {
            margin: 0;
            padding: 0;
            color: white;
        }
        
        .imp__refereidos_1 .wpcf7 {
            background: #F3F7F8;
            padding: 40px 30px;
            border-radius: 20px;
            margin: auto;
        }
        
        .imp__refereidos_1 .vc_column-inner {
            padding-top: 0 !important;
            padding-bottom: 0;
        }
        
        .wpb_column.vc_column_container.vc_col-sm-12 {
            padding-top: 24px;
            padding-bottom: 43px;
        }
        
        h2 {
            font-family: Hind;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            margin: 0;
            padding: 0;
            line-height: 1.25px;
            width: 100%;
            padding-top: 20px;
        }
        
        .imp__refereidos_1 .vc_column-inner form input {
            background: #FFFFFF;
            border: 1px solid #7C98A7;
            box-sizing: border-box;
            border-radius: 6px;
            padding: 8px 12px;
        }
        
        .imp__form_dto span {
            width: 49%;
            margin: 8px 0;
        }
        
        .imp__form br {
            display: none;
        }
        
        .imp__form_dto br {
            display: none;
        }
        
        .imp__form input {
            width: 100%;
            font-family: Hind;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #7C98A7;
        }
        
        .imp__form_dto input {
            width: 100%;
            font-family: Hind;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #7C98A7;
        }
        
        .imp__form span {
            width: 49%;
            margin: 8px 0;
        }
        
        .imp__form p {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        
        .imp__form_dto p {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        
        li.imp__send {
            position: relative;
            list-style: none;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        
        input.wpcf7-form-control.has-spinner.wpcf7-submit {
            background: #0500FF;
            border-radius: 75px;
            font-family: Hind;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            padding: 8px 30px;
        }
        
        span.wpcf7-spinner {
            position: absolute;
            right: -51px;
            top: 5px;
        }
        
        .wpcf7-response-output {
            border-color: white!important;
            text-align: center;
            color: white!important;
        }
        
        .wpcf7-not-valid-tip {
            color: white!important;
        }
        /*MOBILE*/
        
        @media (max-width:800px) {
            .imp__form_dto h2,
            .imp__form h2 {
                text-align: center;
                font-size: 22px;
            }
            .imp__form span,
            .imp__form_dto span {
                width: 100%;
            }
        }
        
        .btn_terminos__refe_content {
            display: none;
        }
        
        .btn_terminos__refe_content a {
            text-align: center!important;
            color: #FFFFFF!important;
            background: #0500FF!important;
            border-radius: 75px!important;
            border-color: #0500FF!important;
            font-size: 12px!important;
            text-transform: uppercase!important;
            padding: 5px 10px!important;
            margin-top: 10px!important;
        }
        .select {
            padding: 0px 15px;
        }
        
        @media (max-width:768px) {
            .btn_terminos__refe_content {
                display: block;
            }
            .info_referido>.vc_col-sm-6 {
                border-bottom: solid 1px #000;
                padding-bottom: 35px;
                margin-bottom: 35px;
            }
            .info_referido .vc_col-sm-6 .vc_col-sm-6:first-child .wpb_single_image {
                margin-bottom: 0;
            }
            .info_referido .vc_col-sm-6 .vc_col-sm-6 {
                width: 50%;
            }
        }
        
        @media (max-width:560px) {
            .btn_terminos__refe_content a {
                font-size: 10px!important;
            }
        }
        
        @media (max-width:480px) {
            .btn_terminos__refe_content a {
                font-size: 8px!important;
            }
        }

        .vc_custom_1657142343083 {
            padding-top: 26px !important;
            padding-bottom: 26px !important;
            background-color: #0500ff !important;
        }
        
        .vc_custom_1657263722368 {
            padding-bottom: 25px !important;
        }
        
        .vc_custom_1657309804556 {
            padding-top: 0px !important;
            padding-bottom: 35px !important;
        }
        
        .vc_custom_1652907201023 {
            padding-bottom: 80px !important;
            background-color: #f1f1f1 !important;
        }
        .imp__entrada_contet .imp__entrada_descript {
            text-align: justify;
        }
        
        .imp__entrada-banner h2 {
            text-transform: capitalize;
        }
        iframe#_hjRemoteVarsFrame {
            display: none !important;
            width: 1px !important;
            height: 1px !important;
            opacity: 0 !important;
            pointer-events: none !important;
        }


</style>



import { render, staticRenderFns } from "./WebReferrals.vue?vue&type=template&id=7817052c&scoped=true&lang=es-ES&"
import script from "./WebReferrals.vue?vue&type=script&lang=js&"
export * from "./WebReferrals.vue?vue&type=script&lang=js&"
import style0 from "./WebReferrals.vue?vue&type=style&index=0&id=7817052c&prod&scoped=true&lang=css&"
import style1 from "./WebReferrals.vue?vue&type=style&index=1&id=7817052c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7817052c",
  null
  
)

export default component.exports